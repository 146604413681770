import { createActionCreator } from "deox";
import { Action, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AppState, setOrgAdminPermissionData } from "@APP/redux";
import { AuthState } from "@APP/redux/reducers/auth";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import { ErpId, Provider, approvedStatuses } from "@APP/constants";
import {
  COP_ACCOUNT_NO_LINKED_ACCOUNT_ERROR_CODE,
  COP_CLOSE_MATCH_ERROR_CODE,
  COP_INVALID_ACCOUNT_TYPE_ERROR_CODE,
  COP_NO_MATCH_ERROR_CODE,
  fetchClearingLedger,
  fetchLinkedERPLedgers,
  fetchERPPlan,
  NO_CARD_PAYMENT_APPLICATION_EXIST_ERROR_CODE,
  NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE,
  NO_USER_SUBSCRIPTION_EXIST_ERROR_CODE,
  COP_DISABLED_ACCOUNT_ERROR_CODE,
} from "@APP/services/api";
import {
  CardPaymentApp,
  CardPaymentApplicationStatus,
  CardPaymentsSettings,
  ClearingLedger,
  CoPStatus,
  ConsentStatus,
  Custodian,
  Ledger,
  ReconsentData,
  SubscriptionFeatureTypes,
  UserInfoData,
  UserOrganisation,
  UserSubscription,
} from "@APP/types";
import CONFIG from "@APP/config";
import { cardPaymentCheck } from "@APP/utils";

export const setAuthLoading = createActionCreator(
  "@AUTH/SET_LOADING",
  (resolve) => (isLoading: boolean) => resolve(isLoading),
);
export const setAuthenticated = createActionCreator(
  "@AUTH/SET_AUTHENTICATED",
  (resolve) => (isAuthenticated: boolean) => resolve(isAuthenticated),
);
export const setSettingsHidden = createActionCreator(
  "@AUTH/SET_SETTINGS_HIDDEN",
  (resolve) => (settingsHidden: boolean) => resolve(settingsHidden),
);

export const isOcmVisible = createActionCreator(
  "@AUTH/SET_OCM_VISIBLE",
  (resolve) => (isOcmVisible: boolean) => resolve(isOcmVisible),
);

export const isDashboardVisible = createActionCreator(
  "@AUTH/SET_DASHBOARD_VISIBLE",
  (resolve) => (isDashboardVisible: boolean) => resolve(isDashboardVisible),
);

export const setUserData = createActionCreator(
  "@AUTH/SET_USER_DATA",
  (resolve) => (userData: AuthState["user"]) => resolve(userData),
);

export const setBankLedgers = createActionCreator(
  "@AUTH/SET_BANK_LEDGERS",
  (resolve) => (bankLedger: Ledger[]) => resolve(bankLedger),
);
export const setClearingLedger = createActionCreator(
  "@AUTH/SET_CLEARING_LEDGER",
  (resolve) => (clearingLedger: ClearingLedger | null) => resolve(clearingLedger),
);
export const setUserSubscription = createActionCreator(
  "@AUTH/SET_USER_SUBSCRIPTION",
  (resolve) => (subscription: UserSubscription | null) => resolve(subscription),
);

export const setReconsentBanks = createActionCreator(
  "@AUTH/SET_RECONSENT_BANKS",
  (resolve) => (data: ReconsentData[]) => resolve(data),
);

export const setUserOrganisationData = createActionCreator(
  "@AUTH/USER_ORGANISATION_DATA",
  (resolve) => (data: UserOrganisation) => resolve(data),
);

export const setCardPaymentApp = createActionCreator(
  "@AUTH/SET_CARD_PAYMENT_USER_DETAILS",
  (resolve) => (data: CardPaymentApp) => resolve(data),
);

export const setCoPStatus = createActionCreator(
  "@AUTH/SET_COP_STATUS",
  (resolve) => (status: CoPStatus) => resolve(status),
);

export const setCardPaymentsCustodians = createActionCreator(
  "@AUTH/SET_CARD_PAYMENTS_CUSTODIANS",
  (resolve) => (data: Custodian[]) => resolve(data),
);

export const setCardPaymentsSettings = createActionCreator(
  "@AUTH/SET_CARD_PAYMENTS_SETTINGS",
  (resolve) => (data: CardPaymentsSettings) => resolve(data),
);

export const login =
  (email: string, password: string) => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch({ type: "@AUTH/LOGIN" });
    dispatch(setAuthLoading(true));

    try {
      const { token } = await API.login(email, password);
      AppLocalStorage.setItem(LocalStorageKey.authToken, token);
      dispatch(setAuthenticated(true));
      dispatch(setAuthLoading(false));
    } catch (err) {
      dispatch(setAuthLoading(false));
      throw err;
    }
  };

export const fetchUserData = () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
  dispatch({ type: "@AUTH/FETCH_USER_DATA" });
  dispatch(setAuthLoading(true));

  try {
    const userInfo = await API.getUserInfo();
    const { currentErp } = await API.getCurrentERP();
    let bankAccounts = null;
    let subscription = null;
    let errorFromBankAccountRequest = null;
    let errorFromSageAPRequest = null;
    let cardPaymentApp = null;
    let erpPlan = null;
    let cardPaymentsCustodians = [];

    try {
      bankAccounts = await dispatch(fetchBankAccounts(userInfo));
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;
      if (
        ![
          NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE,
          COP_ACCOUNT_NO_LINKED_ACCOUNT_ERROR_CODE,
        ].includes(errorCode)
      ) {
        errorFromBankAccountRequest = error;
      }
    }

    // Check if customer is created/not
    // Depending on cust status show and hide settings
    if (CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION) {
      try {
        const customer = await API.getWcfCustomer();
        if (customer && customer?.status === "Submitted") {
          dispatch(setSettingsHidden(false));
        } else {
          dispatch(setSettingsHidden(true));
        }
      } catch (error) {
        dispatch(setSettingsHidden(true));
      }

      // Retrieve application listing
      try {
        const { data: wcfList } = await API.wcfListing();

        const approvedApplications = CONFIG.FEATURES?.WCF_ALLOW_ALL_STATUSES
          ? wcfList.data
          : wcfList.data.filter((wcf: { praeturaStatus: string }) =>
              approvedStatuses.includes(wcf.praeturaStatus),
            );
        const isInvoice = approvedApplications.filter(
          (invoice: { invoiceFinanceRequest: null }) => invoice.invoiceFinanceRequest !== null,
        );

        if (approvedApplications.length > 0 && isInvoice.length === 0) {
          dispatch(isDashboardVisible(true));
          if (bankAccounts && bankAccounts?.length > 0) {
            dispatch(isOcmVisible(true));
          } else {
            dispatch(isOcmVisible(false));
          }
        } else {
          dispatch(isDashboardVisible(false));
        }
      } catch (error) {
        dispatch(isDashboardVisible(false));
        dispatch(isOcmVisible(false));
      }
    }

    /**
     * If subscription feature available,
     * make request for active plan
     */
    if (CONFIG.FEATURES.SUBSCRIPTIONS.TYPE !== SubscriptionFeatureTypes.None && userInfo.org) {
      subscription = await dispatch(fetchUserSubscription());
    }

    if (currentErp !== ErpId.INTERNAL && userInfo.org) {
      const { data } = await fetchLinkedERPLedgers(currentErp);

      if (currentErp === ErpId.SAGE) {
        try {
          erpPlan = await fetchERPPlan(currentErp);
        } catch (error) {
          errorFromSageAPRequest = error;
        }
      }

      if (data) {
        dispatch(setBankLedgers(data));
      }
    }

    if (cardPaymentCheck(userInfo.username) && Provider.isMoneyhub) {
      try {
        cardPaymentsCustodians = await dispatch(fetchCardPaymentsCustodians());

        if (cardPaymentsCustodians.length) await dispatch(fetchCardPaymentsSettings());
      } catch (e) {
        // We can continue working with the app without card payments custodians
      }
    }

    if (Provider.isMaverick) {
      cardPaymentApp = await dispatch(fetchCardPaymentAppDetails());
    }

    if (
      cardPaymentCheck(userInfo.username) &&
      currentErp !== ErpId.INTERNAL &&
      (cardPaymentApp?.status === CardPaymentApplicationStatus.Approved ||
        (Provider.isMoneyhub && !!cardPaymentsCustodians.length))
    ) {
      try {
        const data = await fetchClearingLedger(currentErp);

        dispatch(setClearingLedger(data));
      } catch (e) {
        dispatch(setClearingLedger(null));
      }
    }

    const userData = {
      ...userInfo,
      bankAccounts,
      erp: currentErp === ErpId.NO_ERP ? null : currentErp,
      erpPlan,
      subscription,
      cardPaymentApp,
    };

    dispatch(setUserData(userData as AuthState["user"]));
    dispatch(setAuthenticated(true));
    dispatch(setAuthLoading(false));

    // Set permissions for accounting package and bank accounts based on user roles (for org-admin user)
    dispatch(setOrgAdminPermissionData(userData));

    if (errorFromBankAccountRequest) throw errorFromBankAccountRequest;

    return { ...userData, errorFromSageAPRequest };
  } catch (error) {
    throw error;
  }
};

export const fetchCardPaymentsCustodians =
  () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    try {
      const cardPaymentsCustodians = await API.getCustodiansByType({
        custodianType: "CardPaymentProvider",
        consentStatus: ConsentStatus.Authorized,
      });

      dispatch(setCardPaymentsCustodians(cardPaymentsCustodians));

      return cardPaymentsCustodians;
    } catch (error) {
      throw error;
    }
  };

export const fetchCardPaymentsSettings =
  () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    try {
      const cardPaymentsSettings = await API.fetchCardPaymentsSettings();

      dispatch(setCardPaymentsSettings(cardPaymentsSettings));

      return cardPaymentsSettings;
    } catch (error) {
      throw error;
    }
  };

export const fetchCardPaymentAppDetails =
  () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    try {
      const cardPaymentDetails = await API.getMaverickApp();

      dispatch(setCardPaymentApp(cardPaymentDetails));

      return cardPaymentDetails;
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;

      if (errorCode === NO_CARD_PAYMENT_APPLICATION_EXIST_ERROR_CODE) return null;

      throw error;
    }
  };

export const fetchUserSubscription =
  () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    try {
      const userSubscription = await API.getUserSubscription();
      const plans = await API.getSubscriptionsBillingPlans();

      const usersPlan = plans.find((plan) => plan.id === userSubscription?.planId);

      const subscription: UserSubscription = {
        ...userSubscription,
        plan: usersPlan,
        isPlanFree: usersPlan?.price.amount === "0.00",
      };

      dispatch(setUserSubscription(subscription));

      return subscription;
    } catch (err) {
      const errorCode = err.response?.data?.errorCode;

      if (errorCode === NO_USER_SUBSCRIPTION_EXIST_ERROR_CODE) {
        return null;
      }

      throw err;
    }
  };

export const fetchBankAccounts =
  (userData?: UserInfoData) => async (dispatch: Dispatch, getState: () => AppState) => {
    const userOrg = userData?.org ?? getState().auth.user?.org;

    if (!userOrg) {
      return null;
    }

    let fetchBankAccounts = API.getAllMoneyhubBanksAccounts;
    let fetchCustodians = API.getCustodian;

    if (CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE) fetchBankAccounts = API.getMetroPBankAccounts;

    if (Provider.isMX) {
      fetchBankAccounts = API.getMxBankAccounts;
      fetchCustodians = API.getMxInstitution;
    }

    try {
      const bankAccounts = (await fetchBankAccounts()).data;
      if (bankAccounts) {
        const bankAccountsExtended = await Promise.all(
          bankAccounts
            .filter((account) => !!account?.account?.identification)
            .map(async (account) => {
              let bankId: string;

              if (Provider.isMoneyhub || CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE) {
                bankId = account.bankId.toLowerCase();
              }

              if (Provider.isMX) bankId = account.servicer.identification;

              const bankInfo = await fetchCustodians(bankId!);

              return { ...account, bankInfo };
            }),
        );

        if (
          CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE &&
          bankAccounts !== null &&
          bankAccounts.length
        ) {
          dispatch(setCoPStatus(CoPStatus.Success));
        }

        return bankAccountsExtended;
      }
      return null;
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;

      switch (errorCode) {
        case COP_ACCOUNT_NO_LINKED_ACCOUNT_ERROR_CODE:
          dispatch(setCoPStatus(CoPStatus.NoLinkedAccount));
          break;
        case COP_CLOSE_MATCH_ERROR_CODE:
          dispatch(setCoPStatus(CoPStatus.CloseMatch));
          break;
        case COP_INVALID_ACCOUNT_TYPE_ERROR_CODE:
        case COP_NO_MATCH_ERROR_CODE:
          dispatch(setCoPStatus(CoPStatus.InvalidOrNoMatch));
          break;
        case COP_DISABLED_ACCOUNT_ERROR_CODE:
          dispatch(setCoPStatus(CoPStatus.UnderReview));
      }

      throw error;
    }
  };

const clearAppLocalStorage = () => {
  AppLocalStorage.removeItem(LocalStorageKey.authToken);
  AppLocalStorage.removeItem(LocalStorageKey.sessionAuthLogout);
  AppLocalStorage.removeItem(LocalStorageKey.sessionIdleTime);
  AppLocalStorage.removeItem(LocalStorageKey.authTokenToPayInvoices);
  AppLocalStorage.removeItem(LocalStorageKey.persistStore);
  AppLocalStorage.removeItem(LocalStorageKey.registrationCompleted);
};

export const logout = () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
  dispatch({ type: "@AUTH/LOGOUT" });
  // In order to move to the login page, only by app actions, we need to clear the token from the local storage
  // before landing on the login page for correctless working of the login protector.
  const token = AppLocalStorage.getItem(LocalStorageKey.authToken);

  clearAppLocalStorage();

  API.logout(token as string);
};

/**
 * Soft logout is used to clear the user data from the store and NOT revoking the token
 */
export const softLogout = () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
  dispatch({ type: "@AUTH/LOGOUT" });

  clearAppLocalStorage();
};
