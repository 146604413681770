import { CountryCode } from "libphonenumber-js/types";

import { SubscriptionFeatureTypes } from "@APP/types";
import { LOCALES, Provider, SUPPORTED_LANGUAGES } from "@APP/constants";

import CONFIG from "./config.json";

export type AvailableFeatures =
  | "DASHBOARD"
  | "NATIVE_INVOICING"
  | "CASHFLOW_FORECAST"
  | "CUSTOMER_INVOICES"
  | "PAYMENT_REQUEST"
  | "SUPPLIER_INVOICES"
  | "MAKE_PAYMENT"
  | "WORKING_CAPITAL_FINANCE"
  | "SETTINGS"
  | "MULTI_CHANNEL";

interface AppConfig {
  PRODUCT_NAME: string;
  VERSION: string;
  APP_ENV: string;
  API: {
    ROOT_URL: string;
    VERSION: string;
    WCF_URL?: string;
    CONTAIN?: boolean;
  };
  URLS: {
    REDIRECT_URL_TO_MOBILE?: string;
    ANDROID_MOBILE_APPLICATION_URL?: string;
    IOS_MOBILE_APPLICATION_URL?: string;
    PAYMENT_PROVIDER: {
      TERMS_AND_CONDITIONS_URL?: string;
      CONTACT_US_URL?: string;
      PRIVACY_POLICY?: string;
      APPLY_FOR_SQUARE?: string;
    };
    TERMS_AND_CONDITIONS_URL: string;
    PRIVACY_POLICY_URL: string;
    LEARN_MORE_URL?: string;
    PRAETURA_PRIVACY_POLICY?: string;
  };
  AIS_PROVIDER: typeof Provider["Moneyhub" | "MX"];
  PIS_PROVIDER?: typeof Provider["Maverick"];
  INPUTS: {
    SUPPORTED_CURRENCIES: string[];
    MAXIMUM_TRANSACTION_AMOUNT: number;
    DEFAULT_PHONE_COUNTRY_CODE: CountryCode;
    AVAILABLE_COUNTRIES_PHONE_CODES?: string[];
    BANK_SORT_CODE_MASK: string;
  };
  FEATURES: {
    GENERAL_FEATURES: AvailableFeatures[];
    SETTINGS_TABS: string[];
    SUBSCRIPTIONS: {
      TYPE: SubscriptionFeatureTypes;
    };
    REGISTRATION: {
      MOBILE_FLOW_ENABLED?: boolean;
    };
    SETUP: {
      LINKING_ACCOUNTING_PACKAGE: boolean;
      CLEARING_LEDGER: boolean;
      CONFIRMATION_OF_PAYEE?: boolean;
    };
    RTP: {
      RECURRING_PAYMENTS: boolean;
      CARD_PAYMENTS: boolean;
      CARD_PAYMENT_SINGLE_USER?: boolean;
      CARD_PAYMENT_EMAIL?: string;
    };
    WORKING_CAPITAL_FINANCE_APPLICATION?: boolean;
    WCF_ALLOW_ALL_STATUSES?: boolean;
  };
  DATE_LOCALE: keyof typeof LOCALES;
  LANGUAGE: typeof SUPPORTED_LANGUAGES[number];
  NAVIGATION: {
    MAIN_PAGE: string;
  };
  SUPPORT_EMAIL: string;
  SERVICES: {
    SENTRY?: {
      DSN: string;
    };
    CLARITY?: {
      ID?: string;
    };
    GOOGLE?: {
      RECAPTCHA_SITE_KEY?: string;
    };
  };
}

if (CONFIG.APP_ENV !== "production" || process.env.NODE_ENV !== "production") {
  console.info("App сonfiguration:", CONFIG);
} else {
  console.info(`v${CONFIG.VERSION}`);
}

export default CONFIG as AppConfig;
